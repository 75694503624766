<template>
  <VApp light>
    <VMain>
      <RouterView />
    </VMain>
  </VApp>
</template>

<script>
export default {
  name: 'AuthenticationLayout',
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
